.about {
    display: flex;
    flex-direction: column;
    width: 83%;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin: 25px 0;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: #898989 0px 1px 5px;
}
.about p {
    text-align: justify;
}
