.button-component {
    margin: 0 10px;
    padding: 0;
    border-radius: 5px;
    border: 1px solid var(--first-color);
    width: 200px;
    height: 40px;
    cursor: pointer;
    font-size: large;
}

.button-component a {
    text-decoration: none;
    text-align: center;
    padding-inline: 63px;
    padding-top: 8px;
    padding-bottom: 10px;
}

.button-component:hover {
    box-shadow: #006aff32 0px 1px 5px 2px;
    filter: contrast(1.2);
}
