.list-users {
  display: flex;
  flex-direction: column;
  width: 90%;
  min-width: 220px;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 30px 0;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: #898989 0px 1px 5px;
}

.list-users-table {
  width: 100%;
  overflow-x: scroll;
}

.list-users-table table {
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  height: 300px;
  min-width: 978px;
  overflow-y: scroll;
  margin: auto;
  border-radius: 3px;
}
.list-users-table thead {
  position: sticky;
  display: inline-block;
  top: 0;
  min-width: 100%;
}
.list-users-table tr {
  display: flex;
}
.list-users-table th {
  background-color: var(--first-color);
  color: #fff;
}

.list-users-table tbody {
  min-width: 100%;
}
.list-users-table td,
.list-users-table th {
  display: flex;
  align-items: center;
  padding-left: 10px;
  flex: 1;
}
.list-users-table th {
  padding-block: 10px;
}

.list-users-table tr:nth-child(even) {
  background-color: #fefefe;
}
.list-users-table tr:nth-child(odd) {
  background-color: #f0f0f0;
}
.list-users-table tr:hover {
  box-shadow: #898989 0px 1px 5px;
  background-color: var(--third-color);
}

.list-users-table td:nth-last-child(-n + 2) svg:hover {
  color: #fff;
}

.list-users-table td:nth-last-child(2) a:hover {
  color: #fff;
  background-color: #167bf6;
}
.list-users-table td:nth-last-child(1) a:hover {
  color: #fff;
  background-color: #ff1d51;
}

.list-users-name {
  min-width: 200px;
}

.list-users-email {
  min-width: 300px;
}

.list-users-is-admin {
  min-width: 100px;
}
.list-users-is-active {
  min-width: 75px;
}

.list-users-action {
  justify-content: center;
  min-width: 75px;
}
.list-users-action a {
  padding: 6px 35px;
  font-size: 1rem;
  color: #7a7a7a;
  border-radius: 3px;
}

.list-users h4 {
  margin-top: 25px;
  margin-bottom: 0;
}
.list-users-error-message {
  text-align: left;
  height: 40px;
  font-size: 1rem;
  color: red;
}
.list-user-button-row {
  display: flex;
  justify-content: start;
  margin: 0;
  min-width: 100%;
  flex-wrap: wrap;
}
