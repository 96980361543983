.list-advertisers {
  display: flex;
  flex-direction: column;
  width: 90%;
  min-width: 220px;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 30px 0;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: #898989 0px 1px 5px;
}

.list-advertisers-table {
  width: 100%;
  overflow-x: scroll;
}

.list-advertisers-table table {
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  height: 300px;
  min-width: 978px;
  overflow-y: scroll;
  margin: auto;
  border-radius: 3px;
}

.list-advertisers-table thead {
  position: sticky;
  display: inline-block;
  top: 0;
  min-width: 100%;
}
.list-advertisers-table tr {
  display: flex;
}
.list-advertisers-table th {
  background-color: var(--first-color);
  color: #fff;
}

.list-advertisers-table tbody {
  min-width: 100%;
}
.list-advertisers-table td,
.list-advertisers-table th {
  display: flex;
  align-items: center;
  padding-left: 10px;
  flex: 1;
  overflow: hidden;
}

.list-advertisers-table td span {
  margin: auto;
  text-align: left;
  display: inline-block;
  width: 100%;
  font-size: 0.85rem;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-advertisers-table th {
  justify-content: center;
  padding-block: 10px;
}
.list-advertisers-table tr:nth-child(even) {
  background-color: #fefefe;
}
.list-advertisers-table tr:nth-child(odd) {
  background-color: #f0f0f0;
}
.list-advertisers-table tr:hover {
  box-shadow: #898989 0px 1px 5px;
  background-color: var(--third-color);
}

.list-advertisers-table td:nth-last-child(-n + 2) svg:hover {
  color: #fff;
}

.list-advertisers-table td:nth-last-child(2) a:hover {
  color: #fff;
  background-color: #167bf6;
}
.list-advertisers-table td:nth-last-child(1) a:hover {
  color: #fff;
  background-color: #ff1d51;
}
.list-advertisers-type {
  justify-content: center;
  min-width: 150px;
}

.list-advertisers-name-company {
  justify-content: center;
  min-width: 250px;
}

.list-advertisers-cpf-cnpj {
  justify-content: center;
  width: 200px;
}
.list-advertisers-phone {
  justify-content: center;
  min-width: 200px;
}
.list-advertisers-actions {
  justify-content: center;
  min-width: 75px;
}

.list-advertisers-actions a {
  padding: 6px 35px;
  font-size: 1rem;
  color: #7a7a7a;
  border-radius: 3px;
}

.list-advertisers h4 {
  margin-top: 15px;
  margin-bottom: 0;
}

.list-advertisers-error-message {
  text-align: left;
  height: 40px;
  font-size: 1rem;
  color: red;
}

.filter-advertisers {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
}

.filter-advertisers label {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.filter-advertisers label svg {
  border: 1px solid #aaa;
  padding: 7px;
}
.filter-advertisers button {
  height: fit-content;
  width: fit-content;
  font-size: 1rem;
  padding: 5px;
  margin-bottom: 10px;
}

.filter-advertisers select,
.filter-advertisers input {
  height: fit-content;
  width: fit-content;
  font-size: 1rem;
  padding: 5px;
  margin: 5px;
}
.filter-advertisers select:focus,
.filter-advertisers input:focus {
  outline: none;
}

.filter-list-advertiser-type {
  font-size: 0.9rem;
  font-weight: 500;
  padding-top: 10px;
  padding-inline: 15px;
}

.list-advertisers-button-row {
  display: flex;
  justify-content: start;
  margin: 0;
  min-width: 100%;
  flex-wrap: wrap;
}
