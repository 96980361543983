.footer {
  vertical-align: middle;
  align-items: center;
  width: 100%;
  padding-top: 40px;
  margin-top: 20px;
  height: 100px;
  background-color: var(--first-color);
  color: #fff;
  font-size: 0.7rem;
  line-height: 0;
}

.footer p {
  padding-top: 10px;
}

.footer a {
  text-decoration: none;
  color: #fff;
}
