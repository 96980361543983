.ad-slide-container-horizontal {
  display: flexbox;
  min-height: 120px;
  max-height: 120px;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 10px;
  box-shadow: #e0e0e0 0px 0px 10px 1px;
}

.ad-slideshow-image-horizontal img {
  width: max-content;
  max-width: 100%;
  max-height: 120px;
  height: max-content;
  object-fit: contain;
}

.ad-slide-container-vertical {
  display: flexbox;
  min-height: 400px;
  max-height: 400px;
  width: 290px;
  margin-top: 20px;
  margin-bottom: 45px;
  box-shadow: #e0e0e0 0px 0px 10px 1px;
}

.ad-slideshow-image-vertical img {
  width: max-content;
  max-width: 290px;
  height: max-content;
  min-height: 400px;
  max-height: 400px;
  object-fit: contain;
}

.ad-slide-container-mobile {
  display: flexbox;
  max-height: 300px;
  max-height: 300px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 45px;
  box-shadow: #e0e0e0 0px 0px 10px 1px;
}

.ad-slideshow-image-mobile img {
  width: max-content;
  max-width: 100%;
  height: max-content;
  max-height: 300px;
  object-fit: contain;
}

.ad-slide-container-horizontal-mobile {
  display: flexbox;
  min-height: 300px;
  max-height: 300px;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
  box-shadow: #e0e0e0 0px 0px 10px 1px;
}

.ad-slideshow-image-horizontal-mobile img {
  width: max-content;
  max-width: 100%;
  max-height: 300px;
  height: max-content;
  object-fit: contain;
}

.slide-publication-link {
  text-decoration: none;
  color: initial;
}
