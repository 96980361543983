.show-publication {
  display: flex;
  flex-direction: column;
  width: 83%;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  margin-block: 20px;
  padding: 10px 30px 40px;
  border-radius: 10px;
  box-shadow: #b6b6b6 0px 1px 5px;
}

.show-publication h2 {
  padding-top: 20px;
  padding-inline: 15px;
  text-align: left;
}

.show-publication-images {
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  margin-block: 20px;
}

.show-publication-images img {
  max-width: 100%;
  max-height: 100%;
}

.show-publication-error-message {
  margin-bottom: 10px;
  text-align: left;
  height: 4rem;
  font-size: 1rem;
  color: red;
}

.social-buttons {
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 50%;
  border-radius: 0px 10px 10px 0;
  overflow: hidden;
  padding-top: 5px;
  z-index: 10;
}

.social-buttons-item {
  margin-block: -3px;
}
