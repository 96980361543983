@import url("./fonts.css");
@import url("./colors.css");

body {
  margin: 0;
  scroll-behavior: smooth;
  overscroll-behavior: 1;
}

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: "Spartan Regular";
  font-size: 1rem;
  background-color: var(--second-color);
}

.label-filter-search {
  display: flex;
  width: 100%;
}

.label-filter-search > input {
  display: flex;
  min-width: calc(100% - 55px);
}
