.cards-container {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    width: 100%;
    color: #222;
    padding: 0;
}

.header-cards-container {
    width: 100%;
    text-align: start;
    border-bottom: 1px solid #ccc;
    height: min-content;
    margin-left: 12px;
}

.cards-container h3 {
    margin-block: 5px;
    padding: 0;
}

.list-publication-by-category-pages {
    background-color: var(--first-color);
    margin: 10px;
    border-radius: 3px;
    cursor: pointer;
}
.list-publication-by-category-pages a {
    display: flex;
    flex-shrink: 1;
    min-width: 40px;
    height: 30px;
    font-size: 0.7rem;
    justify-content: center;
    align-items: center;
    color: #fff;
}
