.update-user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: white;
    margin: 30px 0;
    padding: 50px 30px;
    border-radius: 10px;
    box-shadow: #b6b6b6 0px 1px 5px;
}

.update-user input:not([type="checkbox"]) {
    width: 100%;
    font-size: 1rem;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin-bottom: 20px;
    border: solid 1px #ccc;
}

.update-user input:focus {
    outline: solid 1px #999;
    border: solid 1px #fff;
}

.update-user-label-profile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.update-user-label-profile label {
    padding-bottom: 10px;
}

.update-user-button-row {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}

.update-user-error-message {
    margin: 10px;
    text-align: left;
    height: 2rem;
    font-size: 1rem;
    color: red;
}

.hidden-profile {
    display: none;
}

#checkbox-profile {
    margin-bottom: 15px;
}
