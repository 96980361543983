.list-ads {
  display: flex;
  flex-direction: column;
  width: 90%;
  min-width: 220px;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 30px 0;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: #898989 0px 1px 5px;
}

.list-ads-table {
  width: 100%;
  overflow-x: scroll;
}

.list-ads-table table {
  overflow-y: scroll;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  height: 300px;
  min-width: 978px;
  margin: auto;
  border-radius: 3px;
}

.list-ads-table thead {
  position: sticky;
  display: inline-block;
  top: 0;
  min-width: 100%;
}
.list-ads-table tr {
  display: flex;
}
.list-ads-table th {
  background-color: var(--first-color);
  color: #fff;
}

.list-ads-table tbody {
  min-width: 100%;
}
.list-ads-table td,
.list-ads-table th {
  display: flex;
  align-items: center;
  padding-left: 10px;
  flex: 1;
  overflow: hidden;
}

.list-ads-table td span {
  margin: auto;
  text-align: left;
  display: inline-block;
  width: 100%;
  font-size: 0.85rem;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-ads-table th {
  justify-content: center;
  padding-block: 10px;
}
.list-ads-table tr:nth-child(even) {
  background-color: #fefefe;
}
.list-ads-table tr:nth-child(odd) {
  background-color: #f0f0f0;
}
.list-ads-table tr:hover {
  box-shadow: #898989 0px 1px 5px;
  background-color: var(--third-color);
}

.list-ads-table td:nth-last-child(-n + 2) svg:hover {
  color: #fff;
}

.list-ads-table td:nth-last-child(2) a:hover {
  color: #fff;
  background-color: #167bf6;
}
.list-ads-table td:nth-last-child(1) a:hover {
  color: #fff;
  background-color: #ff1d51;
}
.list-ads-advertiser {
  justify-content: center;
  min-width: 100px;
}

.list-ads-position {
  justify-content: center;
  min-width: 100px;
}

.list-ads-sequence,
.list-ads-is-active {
  justify-content: center;
  min-width: 100px;
}

.list-ads-description {
  justify-content: center;
  min-width: 70px;
}

.list-ads-expiration-date {
  justify-content: center;
  min-width: 70px;
}

.list-ads-actions {
  justify-content: center;
  min-width: 75px;
}

.list-ads-actions a {
  padding: 6px 35px;
  font-size: 1rem;
  color: #7a7a7a;
  border-radius: 3px;
}

.list-ads h4 {
  margin-top: 15px;
  margin-bottom: 0;
}

.list-ads-error-message {
  text-align: left;
  height: 40px;
  font-size: 1rem;
  color: red;
}

.filter-ads {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
}

.filter-ads label {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.filter-ads label svg {
  border: 1px solid #aaa;
  padding: 7px;
}
.filter-ads button {
  height: fit-content;
  width: fit-content;
  font-size: 1rem;
  padding: 5px;
  margin-bottom: 10px;
}

.filter-ads select,
.filter-ads input {
  height: fit-content;
  width: fit-content;
  font-size: 1rem;
  padding: 5px;
  margin: 5px;
}
.filter-ads select:focus,
.filter-ads input:focus {
  outline: none;
}

.filter-list-ads-select {
  font-size: 0.9rem;
  font-weight: 500;
  padding-top: 10px;
  padding-right: 20px;
}

.list-ads-button-row {
  display: flex;
  justify-content: start;
  margin: 0;
  min-width: 100%;
  flex-wrap: wrap;
}
