.login {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  margin: 30px 0;
  margin-bottom: 60px;
  padding: 50px 30px;
  border-radius: 10px;
  box-shadow: #b6b6b6 0px 1px 5px;
}

.login input {
  width: 100%;
  font-size: 1rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 20px;
  border: solid 1px #ccc;
}

.login input:focus {
  outline: solid 1px #999;
  border: solid 1px #fff;
}

.login-button-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.login-error-message {
  margin: 10px;
  text-align: left;
  height: 2rem;
  font-size: 1rem;
  color: red;
}
