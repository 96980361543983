.contact {
    display: flex;
    flex-direction: column;
    width: 83%;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin: 30px 0;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: #898989 0px 1px 5px;
}
.contact a,
.contact p {
    display: flex;
    align-items: center;
    font-size: 1rem;
    text-decoration: none;
    color: initial;
}

.contact svg {
    font-size: 2rem;
    margin-right: 10px;
}

.contact-email {
    color: rgb(57, 117, 229);
}
.contact-whatsapp {
    color: rgb(38, 139, 53);
}
