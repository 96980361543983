.new-user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: white;
    margin: 30px 0;
    padding: 50px 30px;
    border-radius: 10px;
    box-shadow: #b6b6b6 0px 1px 5px;
}

.new-user input:not([type="checkbox"]) {
    width: 100%;
    font-size: 1rem;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin-bottom: 20px;
    border: solid 1px #ccc;
}

.new-user input:focus {
    outline: solid 1px #999;
    border: solid 1px #fff;
}

.new-user-inline {
    margin-top: 10px;
    display: inline-flex;
}
.new-user-button-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}

.new-user-error-message {
    margin: 10px;
    text-align: left;
    height: 2rem;
    font-size: 1rem;
    color: red;
}
#checkbox-profile {
    margin-bottom: 15px;
}
