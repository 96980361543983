.page-not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 450px;
}

.page-not-found img {
    width: 45vw;
}

.page-not-found p {
    font-size: large;
}
