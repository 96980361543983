.cards-container {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    width: 100%;
    margin-bottom: 15px;
    padding-top: 20px;
    color: #222;
}

.header-cards-container {
    width: 100%;
    text-align: start;
    border-bottom: 1px solid #ccc;
    height: 50px;
    margin-bottom: 10px;
}
