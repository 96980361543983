.header {
  width: 100%;
  background-color: var(--first-color);
  padding: 0;
}

.header-main-menu,
.header-admin-menu {
  display: flex;
  flex-shrink: 1;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
}

.header-main-menu {
  padding-bottom: 50px;
}

.header-admin-menu {
  justify-content: center;
}

.header-main-menu li,
.header-admin-menu li {
  height: 100%;
  display: flex;
  width: 200px;
  justify-content: center;
  align-items: center;
  list-style: none;
}
.header-main-menu li {
  width: 130px;
}

.header-main-menu li:hover,
.header-admin-menu li:hover {
  background-color: #3a84ed;
}

.header-main-menu li a,
.header-admin-menu li a {
  display: block;
  font-size: 0.9rem;
  color: #fff;
  text-decoration: none;
  padding-inline: 20px;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-inline: 20px;
}

.header-logo {
  position: absolute;

  top: 0;
  left: 0;
  height: 50px;
  padding: 5px;
}

.header-categories-menu {
  display: flex;
  justify-content: center;
  background-color: #fff;
  box-shadow: #888 0px 1px 5px;
  margin: 0;
}

#toggler,
#toggler + label {
  display: none;
}

.header-categories-menu li {
  height: 100%;
  width: 150px;
  list-style: none;
}

.header-categories-menu li:hover {
  background-color: #ddf;
}

.header-categories-menu li a {
  font-size: 0.9rem;
  font-weight: 500;
  color: #333;
  text-decoration: none;
  display: block;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-inline: 20px;
}

@media screen and (max-width: 1000px) {
  .header {
    padding-top: 50px;
    z-index: 2;
  }
  .header-logo {
    position: fixed;
    z-index: 3;
  }
  #toggler + label {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 40px;
    z-index: 1;
    display: flex;
    justify-content: end;
    background-color: var(--first-color);
    color: #fff;
    box-shadow: #888 0px 1px 5px;
    padding: 10px 30px;
    font-size: 2em;
  }
  .header-categories-menu {
    display: flex;
    flex-direction: column;
    max-height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .header-categories-menu li {
    height: 100%;
    width: 100%;
    list-style: none;
  }

  #toggler:checked ~ nav .header-categories-menu,
  #toggler:checked ~ nav .header-main-menu,
  #toggler:checked ~ nav .header-admin-menu {
    width: 100%;
    max-height: 100%;
    box-shadow: #b7b6b6 0px 1px 1px;
  }

  .header-main-menu {
    flex-direction: column;
    max-height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .header-main-menu li {
    height: 100%;
    width: 100%;
    list-style: none;
  }

  .header-admin-menu {
    flex-direction: column;
    max-height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  .header-logo {
    height: 40px;
  }
}
