@font-face {
    font-family: "Spartan Black";
    src: url("../assets/fonts/Spartan/Spartan-Black.ttf") format("truetype");
}

@font-face {
    font-family: "Spartan Bold";
    src: url("../assets/fonts/Spartan/Spartan-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Spartan ExtraBold";
    src: url("../assets/fonts/Spartan/Spartan-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: "Spartan ExtraLight";
    src: url("../assets/fonts/Spartan/Spartan-ExtraLight.ttf")
        format("truetype");
}

@font-face {
    font-family: "Spartan Light";
    src: url("../assets/fonts/Spartan/Spartan-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Spartan Medium";
    src: url("../assets/fonts/Spartan/Spartan-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Spartan Regular";
    src: url("../assets/fonts/Spartan/Spartan-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Spartan SemiBold";
    src: url("../assets/fonts/Spartan/Spartan-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Spartan Thin";
    src: url("../assets/fonts/Spartan/Spartan-Thin.ttf") format("truetype");
}
