.card-publication {
  display: flex;
  margin-block: 20px;
  padding: 10px;
  min-width: 100%;
  height: 100px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: #888 0px 1px 5px;
}

.card-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 100px;
  background-color: #000;
}

.card-image img {
  max-width: 100%;
  max-height: 100%;
}

.card-description {
  width: calc(100% - 150px);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 15px;
}
.card-description h3 {
  margin: 0;
}
.card-description h4,
h5 {
  margin: 0 0 10px 0;
  text-align: left;
  display: -webkit-box;
  width: 100%;
  font-size: 0.85rem;
  line-height: 1.25rem;
  min-height: 40px;
  max-height: 40px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-description p {
  margin: 0;
  font-size: 0.75em;
}

.card-publication-link {
  text-decoration: none;
  color: initial;
}

@media screen and (max-width: 520px) {
  .card-description h4,
  .card-description h5,
  .card-description p {
    font-size: 0.5rem;
  }
}
