.update-advertiser {
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  margin: 30px 0;
  padding: 50px 30px;
  border-radius: 10px;
  box-shadow: #b6b6b6 0px 1px 5px;
}

.update-advertiser input:not([type="checkbox"]),
.update-advertiser select {
  width: 100%;
  font-size: 1rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 20px;
  border: solid 1px #ccc;
}

.update-advertiser input:focus:not([type="radio"]),
.update-advertiser select:focus {
  outline: solid 1px #999;
  border: solid 1px #fff;
}

.update-advertiser-images {
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  margin: 10px 0;
}

.update-advertiser-images img {
  max-width: 100%;
  max-height: 100%;
}

.update-advertiser-inline {
  margin-top: 10px;
  display: inline-flex;
}

.update-advertiser-type {
  display: flex;
  flex-wrap: nowrap;
  justify-content: start;
  width: 100%;
}
.update-advertiser-type label {
  display: flex;
  text-wrap: nowrap;
  margin-right: 30px;
}

.update-advertiser-type label input {
  margin-right: 10px;
}

.update-advertiser-button-row {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.update-advertiser-error-message {
  text-overflow: ellipsis;
  margin-bottom: 10px;
  text-align: left;
  height: 4rem;
  font-size: 1rem;
  color: red;
}
