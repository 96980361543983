.slide-container {
    background-color: #020202;
    text-align: center;
    width: 100%;
    height: 400px;
    margin-top: 20px;
    margin-bottom: 15px;
}

.slideshow-image {
    width: 100%;
    max-height: 400px;
}

.slideshow-image img {
    width: max-content;
    height: max-content;
    max-width: 100%;
    max-height: 400px;
}

.slideshow-caption {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    background-color: #333;
    color: #fff;
}

.slide-publication-link {
    text-decoration: none;
    color: initial;
}
