.new-publication {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: center;
    align-items: flex-start;
    background-color: white;
    margin: 30px 0;
    padding: 50px 30px;
    border-radius: 10px;
    box-shadow: #b6b6b6 0px 1px 5px;
}

.new-publication input:not([type="checkbox"]),
.new-publication select {
    width: 100%;
    font-size: 1rem;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin-bottom: 20px;
    border: solid 1px #ccc;
}

.new-publication input:focus,
.new-publication select:focus {
    outline: solid 1px #999;
    border: solid 1px #fff;
}

.new-publication-images {
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    margin: 10px 0;
}

.new-publication-images img {
    max-width: 100%;
    max-height: 100%;
}

.new-publication-inline {
    margin-top: 10px;
    display: inline-flex;
}
.new-publication-button-row {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}

.new-publication-error-message {
    text-overflow: ellipsis;
    margin-bottom: 10px;
    text-align: left;
    height: 4rem;
    font-size: 1rem;
    color: red;
}
#checkbox-highlight {
    margin-bottom: 15px;
}

#new-publication-content {
    text-align: justify;
    width: 100%;
    height: 300px;
    margin-bottom: 100px;
}
